<template>
  <div>
    <div class="scroll">
<!--      <div class="scrollTop" @click="backTop" v-if="scrollType"></div>-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
.scroll {
  position: relative;
  .scrollTop {
    width: 35px;
    height: 35px;
    position: fixed;
    background: url('../../assets/ico_go_top.png') center
      center no-repeat;
    background-size: 100% 100%;
    border-radius: 50%;
    text-align: center;
    line-height: 35px;
    bottom: 280px;
    right: 20px;
    z-index: 1002;
    border: 1px solid #000;
  }
}
</style>
<script>
export default {
  data() {
    return {
      scrollType: false,
      //   languageSelect: "",
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true); // 这里的true一定要写
  },
  // 离开这个页面销毁滚动条事件，不然会给每一个页面都触发
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll, true); // 这里的true一定要写
  },
  created() {
    // if (localStorage.getItem("language")) {
    //   this.languageSelect = localStorage.getItem("language");
    // } else {
    //   this.languageSelect = "zh";
    // }
    // console.log("cdefe" + this.languageSelect);
  },
  methods: {
    // 回到顶部
    backTop() {
      // document.documentElement.scrollTop = 0;
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      })
    },
    /*如果不想让按钮在一开始的时候存在，而是在滚动了一定的距离的时候再出现，那设置 一个滚动条的监听*/
    handleScroll() {
      let scrollTop = document.documentElement.scrollTop;
      const firstScreenHeight = window.innerHeight;
      if (scrollTop > firstScreenHeight) {
        this.scrollType = true;
        // console.log(firstScreenHeight);
      } else {
        this.scrollType = false;
      }
    },
  },
};
</script>
