module.exports = {
    common: {
        login:  "Log in",
        submit: "Submit",
        reset: "Reset",
        cancel: "Cancel",
        noData: 'No data available',
        article: 'Text',
        webName: 'Oil & Gas Information',
        all: 'All',
        defaultKeywords: 'Oil & Gas Information',
        defaultdDscription: 'To gain insight into the changes in the oil & gas industry and experience large-scale AI models, come to Oil & Gas Information!',
        loginRegi: 'Login/Register',
        confirm: 'Confirm',
        backtop: 'Back Top',
        more: 'More',
        view: 'View',
        share: 'Share',
        collect: 'Collect',
        like: 'Like',
        wxShareTip: 'Open WeChat and "scan" to share with friends and circle of friends',
        label: 'Label',
        search: 'Search'
    },
    index: {
        technology: "Technology",
        news: "News",
        opportunity: "Business",
        report: "Reports",
        more: 'More',
        navTitle: 'Oil & Gas Information - AI Big Model Oil and Gas Information',
        navKeywords: 'Oil & Gas Information, Oil & Gas Information, Oil & Gas Information Platform, Oil & Gas Information Platform, Oil & Gas AI large Model, AI large Model Information, Oil & Gas Business Opportunities, Oil & Gas Reports, Oil & Gas Journals',
        navDescription: 'Oil & Gas Information (oilgasinfoai.com) AI large-model oil and gas information platform provides one-stop intelligent information services. Browse and learn all-round industry information, covering news trends, technological frontiers, industry reports, business opportunity insights, etc.; intelligent search and AI interaction Conversations to create intelligent and personalized user experiences.',
        mLoginTip: 'Click to log in to experience more services'
    },
    header: {
        home:"Home",
        consulting:"AI consulting",
        webName: 'Oil & Gas Information - AI Big Model Oil and Gas Information, ',
        login: "please log in",
        service: "Online Service",
        welcome: 'Hello, {username} Welcome to the Oil & Gas Information',
        oilDao: 'Oil and Gas Dao',
        oilExpo: 'Expo',
        oilCommunity: 'Community',
        oilTalent: 'Talent',
        mobileTerminal: 'Mobile Terminal',
        feedback: 'Feedback',
        searchKeyword: 'Oil & Gas',
        searchHotword1: 'Green energy',
        searchHotword2: 'Petroleum technology',
        searchHotword3: 'Product development',
        searchHotword4: 'Business discovery',
        searchHotword5: 'Knowledge popularization',
    },
    ai: {
        aiMessage:'How can i help you today',
        placeholder:'Please enter the question you want to consult.',
        generating:'Generating. Please wait...',
        navTitle: 'AI Information - AI Large Model Information Platform - Oil & Gas Information',
        navKeywords: 'AI large model, AI information, AI large model information',
        navDescription: 'Oil & Gas Information (oilgasinfoai.com) AI large-model oil and gas information platform, users can check Oil & Gas Information in the form of questions and answers, supporting all-round questions and answers on news, technology, economy, etc.',
        newChat: 'New session',
        historyChat: 'History session',
        noHistory: 'No history, Let\'s talk',
        aiName: "AI q&a assistant",
        tip: "Ask a question or enter what you want to write, and I will provide answers and help immediately",
        serviceability: "The following are the service capabilities I can provide",
        aiChat: "AI conversation",
        aiChatTip: "Through the form of question and answer, you can check Oil & Gas Information, and support all-round questions and answers on news, technology, economy, etc.",
        newsSummary: "News summary",
        newsSummaryTip: "Supports summary and induction of single news, including article summary, keyword generation, and article summary.",
        membership: "Activate membership",
        membershipTip: "AI information conversations are paid rights. Becoming a paying user can have more conversations, ",
        membershipBtn: "Activate now.",
        customizedService: "Customized services",
        customizedServiceTip: "If you want to have a customized version of GPT Oil & Gas Information exclusive to your company, please contact us. ",
        customizedServiceBtn: "Contact information.",
        aiInput: "Please enter the question you need to consult",
        aiInputLoginTip: ", you can send me questions",
        contactMethod: "Contact information",
        qaAdvice: "Question and answer suggestions",
        qaAdviceQ: "Q:",
        qaAdviceA: "A:",
        chatNumTip1: "You have",
        chatNumTip2: "dialogue times, already used",
        chatNumTip3: "times.",
        chatNumTip4: 'More paid benefits.',
        chatNumUseUpTitle: "Kind tips",
        chatNumUseUpTip: "Your free conversation count has been used up. Become a paid user to have more conversation times",
        activate: "Activate now",
        buy: 'Click to buy',
        contact: 'Contact',
        phone: 'Telephone',
        email: 'Mail',
        tipTitle: 'Tips',
        typeLimitTip: 'You can enter up to 3000 characters.',
        timeoutTip: 'There has been no conversation for more than 30 minutes. Please refresh and try again.',
        generateTip: 'Generating, please wait...',
        unabilityTip: "The current round of dialogue is beyond the limits of DRE's ability, we will start a new round of dialogue.",
        replicatingTip: 'Replicating Success',
        findDocTip1: 'Found',
        findDocTip2: ' documents for reference',
        findDocTip3: '',
        findDocTip4: 'document',
    },
    technology: {
        navTitle: 'Cutting-edge science and technology information - Oil & gas industry AI large model information platform - Oil & Gas Information',
        navKeywords: 'Science and technology information, cutting-edge science and technology information, oil & gas industry information, oil and gas information',
        navDescription: 'Oil & Gas Information (oilgasinfoai.com) AI large-model oil and gas information platform, in the cutting-edge science and technology information channel, watch in-depth reports on the oil and gas industry, and grasp industry information and analysis and interpretation anytime and anywhere.'
    },
    technologyDetail: {
        navTitle: 'Oil & gas information technology frontier',
        navKeywords: 'Oil & Gas Information, Oil & Gas Information, Technological Frontiers'
    },
    news: {
        hotTitle:'Hot News',
        navTitle: 'News information - Oil & gas industry AI large model information platform - Oil & Gas Information',
        navKeywords: 'News, oil & gas industry information, oil and gas information',
        navDescription: 'Oil & Gas Information (oilgasinfoai.com) AI large-model oil and gas information platform, in the news channel, watch oil and gas industry news reports, and grasp industry information and analysis and interpretation anytime and anywhere.'
    },
    newsDetail: {
        navTitle: 'Oil & Gas information news',
        navKeywords: 'Oil & Gas information, Oil & Gas information, News',
        next: 'Next',
        likeTip: 'Good article, deserves to be seen by more people',
        firstLike: 'Ask for first likes',
        wx: 'WeChat',
        wxFriend: 'Circle of friends',
        relateNews: 'Related reading',
    },
    report: {
        navTitle: 'Journal Report - Oil & Gas Industry AI Large Model Information Platform - Oil & Gas Information',
        navKeywords: 'Oil & Gas Journals, Oil & Gas Reports, Oil & Gas Journal Reports, Oil & Gas Information',
        navDescription: 'Oil & Gas Information (oilgasinfoai.com) AI large-model oil & gas information platform. In the journal report channel, you can read massive reports on the oil & gas industry and understand the development trends and changing trends of the industry.',
        moreReports: 'More',
        moreJournals: 'More',
        pages: ' page',
        viewReport: 'View Report',
        reportPage: 'Page',
        download: 'Download ',
        overflow: 'Overview',
        viewTip1: 'More detailed information, please log in',
        viewTip2: 'Register and Login',
        viewTip3: 'More details, please click to view',
        viewTip4: 'View report',
        moreRJ: 'More journal reports',
        receive: 'Receive ',
    },
    reports: {
        navTitle: 'Oil & gas industry market report_Oil & gas industry development trend report_Oil & gas information',
        navKeywords: 'Oil & gas report, oil & gas industry report, oil & gas industry research report, market analysis report, industry development report',
        navDescription: 'Oil & Gas Information (oilgasinfoai.com) AI large-model oil & gas information platform provides you with professional oil & gas industry research reports, feasibility analysis reports, and market research reports.',
    },
    journals: {
        navTitle: 'Oil & gas industry journal reports_Oil & gas industry professional journals_Oil & gas information',
        navKeywords: 'Oil & gas industry journal reports_Oil & gas industry professional journals_Oil & gas information',
        navDescription: 'Oil & Gas Information (oilgasinfoai.com) AI large-model oil & gas information platform provides you with professional oil & gas industry research reports, feasibility analysis reports, and market research reports.',
    },
    reportDetail: {
        navTitle: 'Oil & Gas Information Journal Report',
        navKeywords: 'oil & gas journals, oil & gas reports, oil & gas journal reports, journal reports',
    },
    opportunity: {
        navTitle: 'Business opportunities insights - Business opportunities in the oil & gas industry - Oil & gas knowledge',
        navKeywords: 'Business opportunity market, business opportunities, oil & gas information, procurement, purchase, bidding, bidding, inquiry, business opportunity insights',
        navDescription: 'Oil and Gas Knowledge (oilgasinfoai.com) AI large-model oil and gas information platform provides you with massive business opportunity data, insights into business opportunities, and allows you to close business without leaving home.',
        moreR: 'More',
        language: 'Language',
        type: 'Type',
        sort: 'Sort',
        timeSort: 'Time',
        announcement: 'Announcement',
        company: 'Company',
        announceTime: 'Time',
        operate: 'Operate',
        detail: 'Details',
        totalTip1: 'total data:',
        totalTip2: '', // 'pieces of data',
        tender:'Tender',
        rAnnouncement: 'Announce',
        aCompany: 'Company',
        aDetail: 'Announcement details',
        startTime: 'Release time',
        endTime: 'Deadline',
        enterpriseName: 'Company Name',
        brief: 'Announcement Summary'
    },
    opportunityDetail: {
        navTitle: 'Oil & gas information business opportunities insights',
        navKeywords: 'Oil & gas information, Oil & gas information business opportunities, Oil & gas information business opportunities insights, business opportunities',
    },
    aiDialog: {
        serviceTip1: 'I am a question and answer assistant, for the article you have any questions, I will try my best to help you answer.',
        serviceTip2: 'What\'s the summary of the news',
        serviceTip3: 'What is the key words of the news',
        useNumTip1: 'You have',
        useNumTip2: 'dialogue times, already used',
        useNumTip3: 'times.',
        aiName: 'Q&A assistant',
        aiTip1: 'Paid benefits and enterprise-specific customized services',
        aiTip2: 'AI information conversations are paid rights. Becoming a paying user can have more conversations, ',
        aiTip3: 'If you want to have a customized version of GPT oil and gas knowledge exclusive to your company, please contact us. ',
        aiPlaceholder:'Please enter the content you want to consult.'
    },
    search: {
        navTitle: '{title} - Business opportunities in the oil & gas industry - Oil & gas knowledge',
        navKeywords: '{keyword} , Oil & Gas Information, Oil & Gas Information',
        navDescription: 'Oil and gas information (oilgasinfoai.com) provides you with various {keyword} content information, {keyword} information, {keyword} business opportunities, {keyword} journal reports, etc. To find more {keyword} content information, please go to Oil & gas knowledge.',
        hotSearch: 'Popular searches',
        searchResultTip: 'Discover approximately {total} relevant results',
        countdown1: 'From the end',
        countdown2: 'days',
    },
    user: {
        user: 'Personal center',
        logout: 'Logout',
        location: 'Current location',
        info: 'Personal information',
        avatar: 'Avatar',
        uploadAvatar: 'Upload profile picture',
        username: 'Nickname',
        usernameTip: 'Please enter a nickname, up to 20 words',
        realname: 'Name',
        realnameTip: 'Please enter your name',
        sex: 'Sex',
        sexTip: 'Please select gender',
        birthday: 'Date of birth',
        birthdayTip: 'Select date',
        industry: 'Industry',
        industryTip: 'Please enter your industry',
        company: 'Company',
        companyTip: 'Please enter your company',
        duty: 'Job',
        dutyTip: 'Please enter job title',
        interest: 'Reading interest',
        interestTip: 'Please enter your reading interest',
        myCollection: 'My collection',
        unfavorite: 'Unfavorite',
        myOrders: 'My order',
        message: 'Information management',
        secure: 'Subject subscription',
        bindPhone: 'Mobile phone number binding',
        bindEmail: 'Mailbox binding',
        setPwd: 'Login password',
        bindWx: 'Wechat binding',
        immediateBinding: 'Immediate binding',
        unbindImmediately: 'Unbind immediately',
        areaCode: 'Area code',
        areaCodeTip: 'Please select an area code',
        phone: 'Phone',
        phoneTip: 'Please enter your phone number',
        code: 'Verification code',
        codeTip: 'Verification code',
        modifyPhone: 'Modify mobile phone number',
        sendCode: 'Verification code',
        bindingPhone: 'Mobile phone number binding',
        bindingMail: 'Mailbox binding',
        modifyMail: 'Modify email',
        mail: 'Mailbox',
        mailTip: 'Please enter email',
        account: 'Account',
        accountTip: 'Please enter your email/phone number',
        password: 'Password',
        passwordTip: 'Please enter password',
        requirePas: 'Confirm password',
        requirePasTip: 'Please enter your password again',
        modifyPwd: 'Modify password',
    },
    right: {
        latestBusiness: 'Latest Business',
        latestReport: 'Latest Report',
        latestInfo: 'Latest News',
        latestNews: 'Latest News',
        latestRJ: 'Journal Reports',
    },
    member: {
        joinMember: 'Join Membership',
        memberLevel1: 'Bronze Member',
        memberLevel2: 'Platinum Member',
        memberLevel3: 'Gold Member',
        memberBenefits: 'Member Benefits',
        dialogNum: 'Number of Conversations',
        payMethod: 'payment method',
        payWechat: 'WeChat',
        payAlipay: 'Alipay',
        payPaypal: 'Paypal',
        agreeTip: 'I have read and accepted ',
        agreeTip1: '《the Oil & Gas Knowledge User Purchase Agreement》',
        times: ' times',
        privilegeTip: ' Privileges',
        privilege: 'privilege',
        interests: 'rights and interests',
        weChatPay: 'WeChat Pay',
        paymentAmount: 'Payment amount',
        wechatScanTip: 'Use WeChat to scan the QR code to pay'
    },
    extends: {
        collectTip: 'This operation will cancel the collection of the article. Do you want to continue?',
        rejectCollect: 'Cancel collection successful!',
        resolveCollect: 'Collection successful!',
        likeTip: 'This operation will cancel liking. Do you want to continue?',
        rejectLike: 'Cancel like successfully!',
        resolveLike: 'Like successfully!'
    }
}
